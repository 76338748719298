Origin.$(document).on("click",".nav-toggle",function(){
	Origin.$(this).toggleClass("active");
	Origin.$(Origin.$(this).data("target")).toggleClass("show");
});

var stickies=[];
var scrolled=[];
Origin.$(document).ready(function() {
	Origin.$('[data-sticky]').each(function() {
		stickies.push({
			el: Origin.$(this),
			at: Origin.$(this).position().top,
			class: Origin.$(this).data("sticky") == "" ? "nav-sticky" : Origin.$(this).data("sticky"),
			offset: !Origin.$(this).data("sticky-offset") ? 0 : parseInt(Origin.$(this).data("sticky-offset")),
			spacer: false,
			addSpacer: Origin.$(this).data("sticky-spacer") == undefined ? true : Origin.$(this).data("sticky-spacer")
		});
	});
	Origin.$('[data-scrolled]').each(function() {
		scrolled.push({
			el: Origin.$(this),
			at: Origin.$(this).data("scrolled")
		});
	});
	scroll();
})
Origin.$(window).scroll(function() {
	scroll();
});
Origin.$(window).resize(function() {
	resize();
});
function scroll() {
	var top = Origin.$(window).scrollTop();
	stickies.forEach(function(s){
		if( top > s.at + s.offset ) {
			if( s.addSpacer && !s.spacer ) {
				s.spacer = Origin.$("<div class=\"sticky-spacer\" style=\"height:" + s.el.height()  + "px\"></div>");
				s.spacer.insertBefore( s.el );
				scrolled.push({
					el: s.el,
					class: !Origin.$(this).data("scrolled") ? "scrolled" : Origin.$(this).data("scrolled"),
					at: s.at + s.el.height()
				});
			}
			s.el.addClass( s.class );
		} else {
			s.el.removeClass( s.class );
			if( s.spacer ) s.spacer.remove();
			s.spacer = false;
		}
	});
	scrolled.forEach(function(s){
		if( top > s.at ) {
			s.el.addClass( "scrolled" );
		} else {
			s.el.removeClass( "scrolled" );
		}
	});
}
function resize() {
	// TODO: Recalculate the at positions for stickies and scrolleds
}
