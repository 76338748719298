Origin.$(document).ready(function() {

	if ((/Android|iPhone|iPad|iPod|BlackBerry/i).test(navigator.userAgent || navigator.vendor || window.opera)) {
		window.addEventListener("orientationchange", resizeBackground, false);
	} else {
		Origin.$(window).resize(resizeBackground);
	}

	function resizeBackground() {
		// Origin.$(".multi-cover,.cover").height(Origin.$(window).height());
	}
	resizeBackground();
	Origin.$(".multi-cover").each(function() {
		Origin.$(this).owlCarousel({
			singleItem: true,
			addClassActive: true,
			autoPlay: Origin.$(this).data("autoplay") ? Origin.$(this).data("autoplay") : false,
			pauseOnHover: Origin.$(this).data("pause-hover") ? Origin.$(this).data("pause-hover") : false,
			dots: Origin.$(this).data("dots") ? Origin.$(this).data("dots") : false,
			navigation: Origin.$(this).data("navigation") ? Origin.$(this).data("navigation") : false,
			navigationText: ["<i class=\"material-icons\">navigate_before</i>", "<i class=\"material-icons\">navigate_next</i>"]
		});
	});
});
