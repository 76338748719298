
Origin.$(document).ready(function() {

  Origin.$('.parallax').each(function() {
    Origin.$(this).parallax({imageSrc: Origin.$(this).data('parallax-img')});
  });

})

Origin.$(window).scroll(function() {
    Origin.$('.parallax2').each(function() {
		Origin.$(this).css("transform", "translateY(" + (Origin.$(window).scrollTop()) + "px)");
	});
});
