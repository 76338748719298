var Origin = {
    $: jQuery.noConflict(true),
    navScroll: null,
    navInit: function() {
        if (Origin.$('#header[data-nav-sticky]')) {
            var ns = Origin.$('#header');
            Origin.navScroll = {
                el: ns,
                class: ns.data('nav-scroll'),
                position: ns.data('nav-scroll-start')
            };
        }
        Origin.checkNav();
    },
    checkNav: function() {
        if (Origin.navScroll) {
            if (Origin.$(window).scrollTop() > Origin.navScroll.position) {
                Origin.navScroll.el.addClass(Origin.navScroll.class);
            } else {
                Origin.navScroll.el.removeClass(Origin.navScroll.class);
            }
        }
    }
};
Origin.$(window).scroll(function() {
    Origin.checkNav();
});
Origin.$(document).ready(function() {
    Origin.navInit();
});
Origin.$(document).on("click", "#resposive-nav-toggle", function() {
    Origin.$("#primary-nav").toggleClass("show");
    if (Origin.$("#primary-nav").hasClass("show")) {
        Origin.$('#header').addClass("nav-in");
    } else {
        Origin.$('#header').removeClass("nav-in");
    }
});
