Origin.$(function() {
	if (Origin.$("body").hasClass("smooth-scroll")) {
		Origin.$('a[href*="#"]:not([href="#"])').click(function() {
			if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
				var target = Origin.$(this.hash);
				target = target.length ? target : Origin.$('[name=' + this.hash.slice(1) + ']');
				console.log(this.hash,target,target.offset());
				if (target.length) {
					Origin.$('html, body').animate({
						scrollTop: Origin.$(window).scrollTop()+target.offset().top
					}, 1000);
					return false;
				}
			}
		});
	}
});
